.siteFooter {
	--background-color: var(--footer-color-background);
	--color: var(--footer-color-text);
	--font-family: var(--footer-typography-text-family);
	--font-size: var(--footer-typography-text-size);
	--font-weight: var(--footer-typography-text-weight);
	--letter-spacing: var(--footer-typography-text-letter-spacing);
	--line-height: var(--footer-typography-text-line-height);

	@media (--bp-medium) {
		--font-family: var(--footer-typography-text-desktop-family);
		--font-size: var(--footer-typography-text-desktop-size);
		--font-weight: var(--footer-typography-text-desktop-weight);
		--letter-spacing: var(--footer-typography-text-desktop-letter-spacing);
	}

	background-color: var(--background-color);
	color: var(--color);
	display: flex;
	flex-direction: column;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	gap: var(--spacing-s);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	padding: var(--spacing-xl) var(--spacing-l);
	position: relative;
	text-align: center;
	z-index: 50;

	& a,
	& button {
		--color: var(--footer-color-link);
		--color-hover: var(--footer-color-link-hover);
		--color-active: var(--footer-color-link-active);
		--color-focus: var(--footer-color-link-focus);
		--outline-width: var(--footer-typography-link-focus-outline-width);
		--outline-style: var(--footer-typography-link-focus-outline-style);
		--outline-color: var(--footer-typography-link-focus-outline-color);
		--font-family: var(--footer-typography-link-family);
		--font-size: var(--footer-typography-link-size);
		--font-weight: var(--footer-typography-link-weight);
		--letter-spacing: var(--footer-typography-link-letter-spacing);

		@media (--bp-medium) {
			--line-height: var(--footer-typography-text-desktop-line-height);
			--font-family: var(--footer-typography-link-desktop-family);
			--font-size: var(--footer-typography-link-desktop-size);
			--font-weight: var(--footer-typography-link-desktop-weight);
		}
	}
}

.siteFooterList {
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing-xs);
	justify-content: center;
	list-style: none;
	padding: 0;
}

.siteFooterListItem {
	align-items: center;
	display: flex;
	gap: inherit;
}

.siteFooterListItemLink {
	color: inherit;
	text-decoration: none;
}

.siteFooterCopyright {
	font-size: inherit;
	margin-bottom: 0;
	text-wrap: balance;
}
